@font-face {
    font-family: 'nasalization';
    src: url('./fonts/nasalization-rg.woff') format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
}

html {
    scroll-behavior: smooth;
    max-width: 100%;
}
body {
    margin: 0;
    /* overflow-x: hidden; */
    font-family: var(--font-family) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
a {
    color: inherit;
    text-decoration: none;
}

/* #root {
    width: 100vw;
} */

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#feedbacklabelspan {
    transform: translate(-120px, 0px);
    display: none !important;
}

#firstname {
    background-color: yellow;
}

@media only screen and (min-width: 600px) {
    .jumbotron-heading {
        text-align: left;
    }
    #feedbacklabelspan {
        display: inline-block !important;
    }
}

@media only screen and (max-width: 599px) {
    #zsiq_float,
    #feedbacklabel {
        display: none;
    }
}
.jumbotron-description {
    font-size: 24px;
    font-weight: bold;
    color: #777;
    padding-bottom: 32px;
}

.video-list-title {
    margin: 24px 0;
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
}

/* [title='Close'] {
    background-color: red;
}

[title='Close']:hover {
    background-color: red;
} */

/* .MuiDataGrid-root
    .MuiDataGrid-columnsContainer
    .MuiDataGrid-colCell:nth-child(2) {
    position: sticky !important;
    left: 0 !important;
    z-index: 1000; 
    background-color: #ffffff; 
}


.MuiDataGrid-root
    .MuiDataGrid-main
    .MuiDataGrid-colCell:first-child
    .MuiDataGrid-colCellTitle {
    z-index: 1001; 
}


.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-cell:nth-child(2) {
    position: sticky !important;
    left: 0 !important;
    z-index: 999; 
    background-color: #ffffff;
} */
.MuiDataGrid-cell:focus {
    outline: none !important;
}

.react-flow__panel.react-flow__attribution.bottom.right > a {
    display: none;
}

.cke_notifications_area {
    display: none;
}

.highcharts-data-table {
    width: 100%;
    overflow: auto;
}

.highcharts-data-table table {
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    /* max-width: 500px; */
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

.highcharts-description {
    margin: 0.3rem 10px;
}
